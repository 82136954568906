import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { isAggsQuote } from 'businessLogic/aggregators';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { updateItem } from 'helpers/arrayHelpers';
import {
  OVER_9_EXCESS_PERCENTAGE,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { PageTitle } from 'helpers/eventTracking';
import { getExcessFromExcessAmount } from 'helpers/excessAmountHelper';
import {
  petNameReplacer,
  usePetNameReplacerWithDefault,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { excessPlaceholders } from 'helpers/placeholders/excessPlaceholders';
import {
  replacePlaceholdersQuestion,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getVetFeeLimitFromProduct } from 'helpers/productHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { pageGridProps } from 'pages/pet/quote-summary';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import ChooseExcessSection from './ChooseExcessSection';
import {
  ExampleBoxStyled,
  ExampleBoxWrapper,
  ExcessContent,
  FlexibleWidthDividerWithMargins,
  GridItemFlex,
  IconWrapper,
  QuestionFieldWithMargin,
  QuestionFieldWrapper,
  SectionIntroHeading,
  StyledSimplePanel,
} from './styles';
import PercentageExcessSection from '../PercentageExcessSection';
import { SectionIntro } from '../styles';

const EXAMPLE_CLAIM_VALUE = 800;

type CsExcessSection = {
  csPetQuoteSummaryChooseExcessV2: {
    confirm_excess_options_question_over_nine: CsQuestion;
    confirm_excess_options_question_under_nine: CsQuestion;
    confirm_excess_options_question_aggs_under_nine: CsQuestion;
    pets_over_nine_examples: {
      heading: string;
      average_claim_heading: string;
      large_claim_heading: string;
      pet_nine_or_older_intro_heading: string;
      pet_younger_than_nine_intro_heading: string;
      pet_nine_or_older_intro: string;
      pet_younger_than_nine_intro: string;
      excess_text: string;
      total_excess_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      pets_over_nine_examples {
        heading
        average_claim_heading
        large_claim_heading
        pet_nine_or_older_intro_heading
        pet_younger_than_nine_intro_heading
        pet_nine_or_older_intro
        pet_younger_than_nine_intro
        excess_text
        total_excess_text
      }
      confirm_excess_options_question_over_nine {
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
      confirm_excess_options_question_under_nine {
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
      confirm_excess_options_question_aggs_under_nine {
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
    }
  }
`;

type ExcessProps = {
  quoteUpdaters: QuoteUpdaters;
  pageTitle: PageTitle;
  pet: QuoteResponsePetInfo;
  petIndex: number;
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
    showValidation: FieldFunction<QuoteOptions, void>;
  };
};

const PetExcessSection: React.FC<ExcessProps> = ({
  quoteUpdaters,
  pageTitle,
  pet,
  petIndex,
  formValidation,
}) => {
  const {
    pets_over_nine_examples,
    confirm_excess_options_question_over_nine,
    confirm_excess_options_question_under_nine,
    confirm_excess_options_question_aggs_under_nine,
  } = useStaticQuery<CsExcessSection>(query).csPetQuoteSummaryChooseExcessV2;
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const coverStartDate = quote.policyInfo
    ? new Date(quote.policyInfo.coverStartDate)
    : new Date();
  const petIs9YearsOrOlderOnCoverStartDate = isPet9YearsOrOlderOnDate(
    pet.dob,
    coverStartDate
  );

  const getConfirmationExcessOptionsQuestion = (): CsQuestion => {
    if (
      isAggsQuote(quote) &&
      !petIs9YearsOrOlderOnCoverStartDate &&
      quote.petInfos?.[petIndex].voluntaryExcessPercentage ===
        VoluntaryExcessPercentage.EXCESS_20_PERCENT
    ) {
      return confirm_excess_options_question_aggs_under_nine;
    }
    return petIs9YearsOrOlderOnCoverStartDate
      ? confirm_excess_options_question_over_nine
      : confirm_excess_options_question_under_nine;
  };

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const petNameQuestionReplacer = usePetNameReplacerWithDefault(pet.petName);
  const substitutePetName = replacePlaceholdersQuestion(petNameQuestionReplacer);
  const confirmOptionsQuestion = processQuestion(getConfirmationExcessOptionsQuestion());

  const { getError, showValidation } = formValidation;

  const baseExcessPlaceholderData = {
    fixedExcess: getExcessFromExcessAmount(pet.voluntaryExcessAmount),
    percentageExcess: OVER_9_EXCESS_PERCENTAGE,
  };

  const richTextClaimExampleIntroPlaceholderReplacer = replacePlaceholdersRichText(
    excessPlaceholders,
    { ...baseExcessPlaceholderData, claimValue: EXAMPLE_CLAIM_VALUE }
  );

  const richTextLowClaimPlaceholderReplacer = (
    csRichTextString: string,
    makeSubstitutionBold?: boolean
  ): string => {
    const replacePlaceholder = replacePlaceholdersRichText(
      excessPlaceholders,
      { ...baseExcessPlaceholderData, claimValue: EXAMPLE_CLAIM_VALUE },
      makeSubstitutionBold
    );
    return replacePlaceholder(csRichTextString);
  };

  const richTextHighClaimPlaceholderReplacer = (
    csRichTextString: string,
    makeSubstitutionBold?: boolean
  ): string => {
    const replacePlaceholder = replacePlaceholdersRichText(
      excessPlaceholders,
      {
        ...baseExcessPlaceholderData,
        claimValue: getVetFeeLimitFromProduct(quote.quoteOptions.productType) as number,
      },
      makeSubstitutionBold
    );
    return replacePlaceholder(csRichTextString);
  };

  const sectionIntroHeading = richTextClaimExampleIntroPlaceholderReplacer(
    petIs9YearsOrOlderOnCoverStartDate
      ? pets_over_nine_examples.pet_nine_or_older_intro_heading
      : pets_over_nine_examples.pet_younger_than_nine_intro_heading
  );

  const sectionIntro = richTextClaimExampleIntroPlaceholderReplacer(
    petIs9YearsOrOlderOnCoverStartDate
      ? pets_over_nine_examples.pet_nine_or_older_intro
      : pets_over_nine_examples.pet_younger_than_nine_intro
  );
  const updateHasConfirmedPercentageExcess = (value: boolean): void => {
    const updatedConfirmations = updateItem(
      quote.quoteOptions.excessPercentageConfirmations,
      petIndex,
      { hasConfirmedExcessPercentage: value }
    );
    updateQuoteOptions({ excessPercentageConfirmations: updatedConfirmations });
  };

  return (
    <>
      <ChooseExcessSection quoteUpdaters={quoteUpdaters} pet={pet} petIndex={petIndex} />
      <FlexibleWidthDividerWithMargins {...pageGridProps} />
      {!petIs9YearsOrOlderOnCoverStartDate && (
        <>
          <PercentageExcessSection
            quoteUpdaters={quoteUpdaters}
            pet={pet}
            petIndex={petIndex}
          />
          <FlexibleWidthDividerWithMargins {...pageGridProps} />
        </>
      )}
      <StyledSimplePanel hideIcon>
        <div>
          <GridItemFlex>
            <IconWrapper>
              <Icon size="large" name="info" color={colors.notificationInfo} />
            </IconWrapper>
            <SectionIntroHeading>
              {petNameReplacer(pet.petName, sectionIntroHeading)}
            </SectionIntroHeading>
            <ExcessContent>
              <SectionIntro
                pageTitle={pageTitle}
                html={petNameReplacer(pet.petName, sectionIntro)}
              />
            </ExcessContent>
          </GridItemFlex>
          <ExampleBoxWrapper>
            <ExampleBoxStyled
              id={`petsOverNineExcessExampleBox[${petIndex}]`}
              data-cy="petsOverNineExcessExample"
              pageTitle={pageTitle}
              subheading={pets_over_nine_examples.heading}
              averageClaimTextHeading={richTextLowClaimPlaceholderReplacer(
                pets_over_nine_examples.average_claim_heading,
                true
              )}
              averageClaimText={richTextLowClaimPlaceholderReplacer(
                pets_over_nine_examples.excess_text
              )}
              averageClaimPayment={richTextLowClaimPlaceholderReplacer(
                pets_over_nine_examples.total_excess_text
              )}
              largeClaimTextHeading={richTextHighClaimPlaceholderReplacer(
                pets_over_nine_examples.large_claim_heading,
                true
              )}
              largeClaimText={richTextHighClaimPlaceholderReplacer(
                pets_over_nine_examples.excess_text
              )}
              largeClaimPayment={richTextHighClaimPlaceholderReplacer(
                pets_over_nine_examples.total_excess_text
              )}
            />
          </ExampleBoxWrapper>
          <QuestionFieldWrapper>
            <QuestionFieldWithMargin
              question={substitutePetName(confirmOptionsQuestion)}
              errorText={getError('excessPercentageConfirmations', [
                'hasConfirmedExcessPercentage',
                petIndex,
              ])}
              gridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
              className={
                getError('excessPercentageConfirmations', [
                  'hasConfirmedExcessPercentage',
                  petIndex,
                ])
                  ? 'validationFailed'
                  : 'validationSuccess'
              }>
              <BooleanRadioInput
                id={`excessPercentageConfirmations[${petIndex}].hasConfirmedExcessPercentage`}
                value={
                  quote.quoteOptions.excessPercentageConfirmations[petIndex]
                    .hasConfirmedExcessPercentage
                }
                onChange={(hasConfirmedExcessPercentage) => {
                  updateHasConfirmedPercentageExcess(hasConfirmedExcessPercentage);
                  showValidation('excessPercentageConfirmations', [
                    'hasConfirmedExcessPercentage',
                    petIndex,
                  ]);
                }}
              />
            </QuestionFieldWithMargin>
          </QuestionFieldWrapper>
        </div>
      </StyledSimplePanel>
    </>
  );
};

export default PetExcessSection;
