import Image from '@rsa-digital/evo-shared-components/components/Image';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledSimplePanel = styled(SimplePanel)`
  && {
    padding: ${spacing(3)};
    margin-bottom: ${spacing(6)};
    box-shadow: none;
    border: solid 1px ${colors.core01};
  }
`;

export const StyledImage = styled(Image)`
  height: ${spacing(4)};
  width: auto;
  margin-bottom: ${spacing(2)};
`;
