import { ButtonStyledAsLink } from '@rsa-digital/evo-shared-components/components/Link/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

export const TabletLandscape = styled.div`
  display: none;

  ${mediaQuery.tabletLandscape`
    display: flex;
  `}
`;

export const TabletPortrait = styled.div`
  display: none;

  ${mediaQuery.tabletPortrait`
    display: flex;
  `}

  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const Mobile = styled.div`
  display: flex;

  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

export const PriceBannerTextWrapper = styled.div`
  margin-top: ${spacing(1)};

  display: flex;

  ${mediaQuery.desktop`
    margin-left: ${spacing(3)};
  `}
`;

export const MobilePriceBannerTextWrapper = styled.div`
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: 0
  `};

  padding-right: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EmphasisedStyling = css`
  ${fonts.paragraphLarge};
  & {
    color: ${colors.core01};
    font-weight: 500;
  }

  margin: 0 0 0 ${spacing(1)};

  text-align: center;
  vertical-align: bottom;
`;

export const EmphasisedText = styled.p`
  ${EmphasisedStyling};
  ${mediaQuery.desktop`
    margin-left: ${spacing(2)}
  `}
`;

export const EmphasisedTextWithoutPadding = styled.p`
  ${EmphasisedStyling};
`;

export const VerticalDivider = styled.div`
  border-right: 1px;
  border-right-style: solid;
  border-right-color: ${colors.core01};

  padding-left: ${spacing(1)};

  ${mediaQuery.desktop`
    padding-left: ${spacing(2)};
  `}
`;

export const StyledText = styled.p`
  ${fonts.paragraph}

  margin: ${spacing(0.5)} 0 0 ${spacing(1)};
  
  & {
    color: ${colors.core01};
  }
  
  text-align: center;
  vertical-align: bottom;
`;

export const StyledLinkText = styled(ButtonStyledAsLink)`
  ${fonts.paragraph}
  text-decoration: underline;
  & {
    color: ${colors.core01};
  }

  margin: ${spacing(0.5)} 0 0 ${spacing(1)};

  text-align: center;
  vertical-align: bottom;

  ${mediaQuery.desktop`
    margin-left: ${spacing(2)};
  `};
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;
