import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useQuoteUpdaters from 'apiHelpers/quote/useUpdateQuoteCover';
import { isAggsQuote } from 'businessLogic/aggregators';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useReducer, useRef, useState } from 'react';
import AddAnotherPetModal from 'components/AddAnotherPetModal';
import AggregatorAgreements from 'components/Aggregators/AggregatorAgreements';
import AggregatorSummary from 'components/Aggregators/AggregatorSummary';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import DiscountPanel from 'components/DiscountPanel';
import Layout from 'components/Layout';
import { BannerContainer } from 'components/Layout/styles';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import PromotionalBox from 'components/PromotionalBox';
import CoverPanel from 'components/QuoteSummary/CoverPanel';
import ExcessSection from 'components/QuoteSummary/ExcessSection';
import PolicyDocumentsSection from 'components/QuoteSummary/PolicyDocumentsSection';
import { PriceBannerText } from 'components/QuoteSummary/PriceBannerText';
import PromoCodeQuestion from 'components/QuoteSummary/PromoCodeQuestion';
import InfoBoxSection from 'components/QuoteSummary/SummaryOfYourNeeds/InfoBoxSection';
import SummaryOfYourNeedsSection from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsSection';
import RichTextLarge from 'components/RichTextLarge';
import RichTextWithModal from 'components/RichTextWithModal';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import TreatmentCostModal from 'components/TreatmentCostModal';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import MarketingConsentSection from 'forms/AboutYourPolicyForm/MarketingConsentSection';
import useMarketingConsentRules from 'forms/AboutYourPolicyForm/MarketingConsentSection/validation';
import { PrivacyPolicy, PrivacyPolicyHeading } from 'forms/AboutYourPolicyForm/styles';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { useValidOfferPanel, useValidQuoteSummaryOffers } from 'helpers/discountHelpers';
import { trackCheckoutEvent, useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackFieldError,
  trackModalOpen,
  trackRichTextLinkClick,
  trackSwitchPaymentClick,
  trackTextButtonClick,
  trackVetFeeLimitSelection,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { getPetTypesBeingInsured, PetType } from 'helpers/getPetTypesBeingInsured';
import { usePageTracking } from 'helpers/pageTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { trySaveQuote, useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  getSelectedProductPrices,
  useCurrentQuote,
  useUpdateQuoteOptions,
} from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import useScrollCallback from 'helpers/useScrollCallback';
import useUpdateNumberOfPets from 'helpers/useUpdateNumberOfPets';
import { getVetFeeLimitName } from 'helpers/vetFeeLimitHelper';
import { PolicyDetails, usePolicyDetails } from 'state/formData/policyDetails';
import {
  initialQuoteOptions,
  ProductType,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import useReferenceData from 'state/referenceData/useReferenceData';
import {
  CsCashbackPanelVariant,
  CsHero,
  CsIcon,
  CsModal,
  CsOffer,
  CsQuestion,
} from 'types/contentStack';
import {
  ExcessSectionHeading,
  InfoPanelWithMargin,
  LargeRichTextWithModalAndMargin,
  OfferPanel,
  PriceBannerSpace,
  QuestionFieldWithMarginTop,
  SectionHeadingWithMargin,
  StyledFormFooter,
  StyledPriceBanner,
  SummaryOfNeedsSectionHeading,
  VetFeeLimitInfoPanel,
  VetFeeLimitRadioInput,
} from './styles';
import useQuoteSummaryRules from './validation';

const SCROLL_UPDATE_RATE_MS = 50;
const PAGE_LANDING_STEP = 6;
const COVER_SELECTION_STEP = 7;

export const premierCoverFeeLimitsSelectorId = 'premierCoverFeeLimitsSelector';

export const pageGridProps = {
  desktop: 8,
  tabletLandscape: 8,
  tabletPortrait: 6,
};

type QuoteSummaryProps = {
  data: {
    coverLevel: ProductType | undefined;
    csPetQuoteSummaryV2: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      select_your_cover_section_heading: string;
      set_your_excess_section_heading: string;
      summary_of_your_needs_section_heading: string;
      successful_save_modal: [CsModal] | [];
      price_banner: {
        monthly_label: string;
        annual_label: string;
        monthly_label_variant_rich_text: string;
        annual_label_variant_rich_text: string;
      };
      add_another_pet_promo_box: {
        icon: [CsIcon];
        heading: string;
        less_than_three_pets_information: string;
        three_pets_information: string;
        add_another_pet_button_text: string;
        add_another_pet_button_screenreader_text: string;
      };
    };
    csPetQuoteSummaryFindTheRightCoverV2: {
      lifetime_cover_text: string;
      policies_not_offered_text: string;
      section_intro_text: string;
      section_intro_text_multipet: string;
      vet_fee_limit_question: CsQuestion;
      vet_fee_limit_info_box_text: string;
      vet_fee_limit_info_box_text_multi_pet: string;
    };
    csPetOfferPanel: {
      panel_icon: [CsIcon];
      panel_content_variations: CsCashbackPanelVariant[];
      offer_end_date: string;
      offer_start_date: string;
    };
    csPetAboutYourPolicy: {
      privacy_policy_section_heading: string;
      privacy_policy: string;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
        excess_spinner_text: string;
        continue_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryV2 {
      meta_title
      hero {
        heading
        subheading
      }
      select_your_cover_section_heading
      set_your_excess_section_heading
      summary_of_your_needs_section_heading
      add_another_pet_promo_box {
        icon {
          icon_code
        }
        heading
        three_pets_information
        less_than_three_pets_information
        add_another_pet_button_text
        add_another_pet_button_screenreader_text
      }
      next_button_text
      successful_save_modal {
        modal_id
      }
      price_banner {
        monthly_label
        annual_label
        monthly_label_variant_rich_text
        annual_label_variant_rich_text
      }
    }
    csPetQuoteSummaryFindTheRightCoverV2 {
      lifetime_cover_text
      policies_not_offered_text
      section_intro_text
      section_intro_text_multipet
      vet_fee_limit_info_box_text
      vet_fee_limit_info_box_text_multi_pet
      vet_fee_limit_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetOfferPanel {
      panel_icon {
        icon_code
      }
    }
    csPetAboutYourPolicy {
      privacy_policy_section_heading
      privacy_policy
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
        continue_spinner_text
      }
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  data: {
    csPetQuoteSummaryV2: {
      meta_title,
      hero,
      next_button_text,
      select_your_cover_section_heading,
      set_your_excess_section_heading,
      summary_of_your_needs_section_heading,
      price_banner,
      add_another_pet_promo_box,
    },
    csPetQuoteSummaryFindTheRightCoverV2: {
      lifetime_cover_text,
      policies_not_offered_text,
      section_intro_text,
      section_intro_text_multipet,
      vet_fee_limit_question,
      vet_fee_limit_info_box_text,
      vet_fee_limit_info_box_text_multi_pet,
    },
    csPetOfferPanel: { panel_icon: csOfferPanelIcon },
    csPetAboutYourPolicy: { privacy_policy, privacy_policy_section_heading },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, excess_spinner_text, continue_spinner_text },
    },
  },
}) => {
  const quote = useCurrentQuote();
  const [policyDetails] = usePolicyDetails();

  const quoteUpdaters = useQuoteUpdaters();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const vetFeeLimitQuestion = processQuestion(vet_fee_limit_question);

  const selectYourCoverSectionId = 'select-your-cover-section';
  const excessSectionId = 'excess-section';
  const summaryOfYourNeedsSectionId = 'summary-of-your-needs-section';

  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const multipetQuote = (quote.petInfos?.length || 0) > 1;

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const { isAnnualPayment } = quoteOptions;

  const [showStickyBannerControl, setShowStickyBannerControl] = useState<boolean>(false);
  const [showStickyBannerVariant, setShowStickyBannerVariant] = useState<boolean>(
    !!quoteOptions.productType
  );

  const [isContinueSelect, setIsContinueSelect] = useState<boolean>(false);

  const claimsCoveredContainerRef = useRef<HTMLDivElement>(null);

  const selectedProductPrices = getSelectedProductPrices(quote);

  const totalSelectedPrice = isAnnualPayment
    ? selectedProductPrices?.annualPrice.total
    : selectedProductPrices?.monthlyPrice.total;

  const updateScrollCallback = useCallback(() => {
    if (!claimsCoveredContainerRef.current) {
      return;
    }
    const claimsCoveredContainerBoundingRectangle = claimsCoveredContainerRef.current.getBoundingClientRect();
    setShowStickyBannerControl(claimsCoveredContainerBoundingRectangle.bottom < 0);
  }, [claimsCoveredContainerRef]);

  useScrollCallback(updateScrollCallback, SCROLL_UPDATE_RATE_MS);

  const validationRules = useQuoteSummaryRules();
  const marketingConsentRules = useMarketingConsentRules();

  const formDetails: QuoteOptions & PolicyDetails = { ...quoteOptions, ...policyDetails };

  const formValidation = useValidation(
    formDetails,
    { ...validationRules, ...(isAggsQuote(quote) ? marketingConsentRules : {}) },
    trackFieldError
  );
  const { getError, validateOnSubmit } = formValidation;

  const defaultErrorHandling = useDefaultErrorHandling();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const richTextPlaceholderReplacer = quote
    ? replacePlaceholdersRichText(quotePlaceholders, quote)
    : noOpPlaceholderReplacer;

  const vetFeeLimitInfoText = multipetQuote
    ? vet_fee_limit_info_box_text_multi_pet
    : vet_fee_limit_info_box_text;

  const [showAddAnotherPetModal, toggleAddAnotherPetModal] = useReducer(
    (state) => !state,
    false
  );

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      isAnnualPayment ? 'monthly' : 'annually'
    );
    updateQuoteOptions({
      isAnnualPayment: !isAnnualPayment,
    });
  };

  const numberOfPetsInQuote = quote.petInfos?.length ?? 0;

  const moveNext = async (): Promise<void> => {
    try {
      await trySaveQuote({
        onSuccess: () => navigate(quoteAndBuyRoutes.checkYourDetails),
        quoteUpdaters,
      });
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  };

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.QuoteSummary);

  const quoteSummaryOffers = useValidQuoteSummaryOffers();
  const offerPanelIcon = unwrapSingleton(csOfferPanelIcon);
  const validOfferPanelVariant = useValidOfferPanel();

  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, true);

  const { addPet } = useUpdateNumberOfPets();

  // since we remove the £500 excess option for low vet fee limit quotes, we need to reset any £500 excess values to the default £100
  const reset500ExcessForLowVetFees = async (productType: ProductType): Promise<void> => {
    if (
      (productType === ProductType.Vet_Fee_Limit_2000 ||
        productType === ProductType.Vet_Fee_Limit_3000) &&
      quote.petInfos &&
      quote.petInfos.some(
        (petInfo) => petInfo.voluntaryExcessAmount === VoluntaryExcessAmount.Excess_500
      )
    ) {
      const updatedExcessAmounts = quote.petInfos.map(
        (petInfo): VoluntaryExcessAmount => {
          return petInfo.voluntaryExcessAmount === VoluntaryExcessAmount.Excess_500
            ? VoluntaryExcessAmount.Excess_100
            : petInfo.voluntaryExcessAmount;
        }
      );

      await quoteUpdaters?.updatePetVoluntaryExcessAmounts(updatedExcessAmounts);
    }
  };

  const petTypesBeingInsured = quote.petInfos
    ? getPetTypesBeingInsured(quote.petInfos)
    : PetType.CatsAndDogs;

  const isMultipetQuote = !!quote.petInfos && quote.petInfos?.length > 1;

  const loadingMsgText = (): string => {
    if (isSaveInProgress) return email_spinner_text;
    if (isContinueSelect) return continue_spinner_text;
    return excess_spinner_text;
  };

  const [showTypicalTreatmentCostModal, setShowTypicalTreatmentModal] = useState(false);
  const showTypicalTreatmentCostModalFunc = (e: any): void => {
    const modalId = e.target.parentNode.getAttribute('data-modal');
    if (modalId === 'pet_treatment_cost_modal') {
      setShowTypicalTreatmentModal(true);
    }
  };

  return (
    <LoadQuoteWrapper>
      <Layout
        metaTitle={meta_title}
        currentStep={QuoteAndBuyStep.QuoteSummary}
        pageTitle={PageTitle.QuoteSummary}
        heading={hero.heading}
        subheading={hero.subheading}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        <Grid>
          <GridItem {...pageGridProps}>
            {(quoteUpdaters?.isLoading || isSaveInProgress) && (
              <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
            )}
            <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError, true)}>
              {isAggsQuote(quote) && (
                <AggregatorSummary
                  aggregator={quote.productId}
                  pageTitle={PageTitle.QuoteSummary}
                />
              )}
              {validOfferPanelVariant && (
                <OfferPanel
                  data-cy="cashback_panel"
                  pageTitle={PageTitle.QuoteSummary}
                  heading={validOfferPanelVariant.heading}
                  information={validOfferPanelVariant.panel_text}
                  icon={offerPanelIcon?.icon_code}
                />
              )}
              <LargeRichTextWithModalAndMargin
                data-cy="lifetime_cover_text"
                pageTitle={PageTitle.QuoteSummary}
                html={lifetime_cover_text}
              />
              <InfoPanelWithMargin>
                <RichText html={policies_not_offered_text} />
              </InfoPanelWithMargin>
              <section aria-labelledby={selectYourCoverSectionId}>
                <SectionHeadingWithMargin
                  heading={plainTextPlaceholderReplacer(
                    select_your_cover_section_heading
                  )}
                  id={selectYourCoverSectionId}
                />
                <RichTextLarge
                  data-cy="section_intro_text"
                  html={
                    isMultipetQuote
                      ? section_intro_text_multipet
                      : petNameReplacer(
                          quote.petInfos ? quote.petInfos[0].petName : '',
                          section_intro_text
                        )
                  }
                  pageTitle={PageTitle.QuoteSummary}
                />
                <QuestionFieldWithMarginTop
                  question={vetFeeLimitQuestion}
                  errorText={getError('productType')}
                  gridItemProps={pageGridProps}
                  onLinkClick={(e) => showTypicalTreatmentCostModalFunc(e)}
                  initiallyShowTooltip>
                  <VetFeeLimitRadioInput
                    id="cover-level-radio"
                    value={quoteOptions.productType}
                    orientationOverride={Orientation.horizontal}
                    buttonsPerRow={{ mobile: 3, tabletPortrait: 3 }}
                    options={[
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_2000),
                        value: ProductType.Vet_Fee_Limit_2000,
                      },
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_3000),
                        value: ProductType.Vet_Fee_Limit_3000,
                      },
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_5000),
                        value: ProductType.Vet_Fee_Limit_5000,
                      },
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_7500),
                        value: ProductType.Vet_Fee_Limit_7500,
                      },
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_9000),
                        value: ProductType.Vet_Fee_Limit_9000,
                      },
                      {
                        name: getVetFeeLimitName(ProductType.Vet_Fee_Limit_12000),
                        value: ProductType.Vet_Fee_Limit_12000,
                      },
                    ]}
                    onChange={(e) => {
                      updateQuoteOptions({
                        productType: e.target.value as ProductType,
                      });
                      reset500ExcessForLowVetFees(e.target.value as ProductType);
                      trackCheckoutEvent(
                        COVER_SELECTION_STEP,
                        quote,
                        catBreedsRefData,
                        dogBreedsRefData,
                        e.target.value as ProductType
                      );
                      trackVetFeeLimitSelection(
                        getVetFeeLimitName(e.target.value as ProductType),
                        vetFeeLimitQuestion.questionText
                      );
                      setShowStickyBannerVariant(true);
                    }}
                  />
                </QuestionFieldWithMarginTop>
                {showTypicalTreatmentCostModal && (
                  <TreatmentCostModal
                    onClose={() => {
                      setShowTypicalTreatmentModal(false);
                    }}
                    pageTitle={PageTitle.QuoteSummary}
                  />
                )}
                {quoteOptions.productType && (
                  <>
                    <VetFeeLimitInfoPanel id="vet-fee-limit-info-panel">
                      <RichTextWithModal
                        html={richTextPlaceholderReplacer(vetFeeLimitInfoText)}
                        pageTitle={PageTitle.QuoteSummary}
                      />
                    </VetFeeLimitInfoPanel>
                    {quoteSummaryOffers &&
                      quoteSummaryOffers.map((offer: CsOffer) => (
                        <DiscountPanel
                          key={offer.offer_start_date}
                          offerPanel={offer}
                          pageTitle={PageTitle.QuoteSummary}
                        />
                      ))}
                    <CoverPanel
                      isAnnualPayment={isAnnualPayment}
                      paymentOnClick={paymentOnClick}
                      vetFeeLimit={quoteOptions.productType}
                      claimsCoveredContainerRef={claimsCoveredContainerRef}
                      petTypesBeingInsured={petTypesBeingInsured}
                    />
                  </>
                )}
                {isQuoteOptionSelectionValid(quote.quoteOptions) && (
                  <Grid alignLeft>
                    <GridItem desktop={8} tabletLandscape={8}>
                      <PromotionalBox
                        pageTitle={PageTitle.QuoteSummary}
                        id="add-another-pet-promotional-box"
                        icon={unwrapSingleton(add_another_pet_promo_box.icon)?.icon_code}
                        heading={add_another_pet_promo_box.heading}
                        information={
                          numberOfPetsInQuote < 3
                            ? add_another_pet_promo_box.less_than_three_pets_information
                            : add_another_pet_promo_box.three_pets_information
                        }
                        button={
                          numberOfPetsInQuote < 3
                            ? {
                                buttonText:
                                  add_another_pet_promo_box.add_another_pet_button_text,
                                screenReaderText:
                                  add_another_pet_promo_box.add_another_pet_button_text,
                                onClick: () => {
                                  toggleAddAnotherPetModal();
                                  trackModalOpen('addAnotherPet');
                                },
                              }
                            : undefined
                        }
                      />
                    </GridItem>
                  </Grid>
                )}
              </section>
              {isQuoteOptionSelectionValid(quote.quoteOptions) && (
                <>
                  <section aria-labelledby={excessSectionId} data-cy="excessSection">
                    <ExcessSectionHeading
                      heading={set_your_excess_section_heading}
                      id={excessSectionId}
                    />
                    <ExcessSection
                      quoteUpdaters={quoteUpdaters}
                      pageTitle={PageTitle.QuoteSummary}
                      formValidation={formValidation}
                    />
                  </section>
                  <section aria-labelledby={summaryOfYourNeedsSectionId}>
                    <FlexibleWidthDivider {...pageGridProps} />
                    <PolicyDocumentsSection />
                    <FlexibleWidthDivider {...pageGridProps} />
                    <SummaryOfNeedsSectionHeading
                      heading={summary_of_your_needs_section_heading}
                      id={summaryOfYourNeedsSectionId}
                    />
                    <SummaryOfYourNeedsSection
                      pageTitle={PageTitle.QuoteSummary}
                      formValidation={formValidation}
                    />
                    <InfoBoxSection />
                    <FlexibleWidthDivider {...pageGridProps} />
                    <WhyChooseUsSection pageTitle={PageTitle.QuoteSummary} />
                    {!isAggsQuote(quote) && (
                      <>
                        <FlexibleWidthDivider {...pageGridProps} />
                        <PromoCodeQuestion
                          id="promoCodePanel"
                          inputId="promoCode"
                          getError={getError}
                          pageTitle={PageTitle.QuoteSummary}
                        />
                      </>
                    )}
                    <section aria-label="Payment Overview">
                      <PricingOverviewPanel
                        data-cy="pricingOverviewPanel"
                        pageTitle={PageTitle.QuoteSummary}
                        includeDiscounts
                        paymentOnClick={paymentOnClick}
                      />
                    </section>
                  </section>
                  {isAggsQuote(quote) && (
                    <>
                      <MarketingConsentSection
                        getError={getError}
                        gridItemProps={pageGridProps}
                        pageTitle={PageTitle.QuoteSummary}
                      />
                      <PrivacyPolicyHeading>
                        {privacy_policy_section_heading}
                      </PrivacyPolicyHeading>
                      <PrivacyPolicy
                        pageTitle={PageTitle.QuoteSummary}
                        html={privacy_policy}
                        onLinkClick={trackRichTextLinkClick(PageTitle.QuoteSummary)}
                      />
                      <AggregatorAgreements pageTitle={PageTitle.QuoteSummary} />{' '}
                    </>
                  )}
                </>
              )}
              <StyledFormFooter
                backButton={{
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Back');
                    navigate(quoteAndBuyRoutes.aboutYourPolicy);
                  },
                }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Continue');
                    setIsContinueSelect(true);
                  },
                }}
                saveButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: saveAndEmailQuote,
                      }
                    : undefined
                }
                printButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: () => window.print(),
                      }
                    : undefined
                }
                pageTitle={PageTitle.QuoteSummary}
              />
            </form>
          </GridItem>
        </Grid>
        {savedQuoteConfirmationModal}
        {showAddAnotherPetModal && (
          <AddAnotherPetModal
            data-cy="addAnotherPetModal"
            onClose={toggleAddAnotherPetModal}
            onConfirm={() => {
              addPet();
              navigate(
                `${quoteAndBuyRoutes.aboutYourPet}#petDetails[${numberOfPetsInQuote}]`
              );
              toggleAddAnotherPetModal();
            }}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        <BannerContainer>
          <StyledPriceBanner
            show={showStickyBannerVariant}
            price={formatInPoundsWithSign(totalSelectedPrice)}
            priceLabel={
              isAnnualPayment
                ? price_banner.annual_label_variant_rich_text
                : price_banner.monthly_label_variant_rich_text
            }
            labelFirst
            leftHandComponent={<PriceBannerText />}
          />
        </BannerContainer>
      </Layout>
      {showStickyBannerControl && <PriceBannerSpace />}
    </LoadQuoteWrapper>
  );
};

export default QuoteSummary;
