import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Modal, { ModalProps } from '@rsa-digital/evo-shared-components/components/Modal';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import fluidGridColumns from '@rsa-digital/evo-shared-components/theme/fluidGridColumns';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import {
  PageTitle,
  trackModalLinkClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  BodyRichText,
  DifferentNeedsRichText,
  ModalGridItem,
  ModalHeading,
  RadioInputGrid,
} from './styles';

type AddAnotherPetModalProps = {
  onConfirm: () => void;
  pageTitle: PageTitle;
} & ModalProps &
  ComponentProps;

type CsAddAnotherPetModal = {
  csPetQuoteSummaryV2: {
    add_another_pet_modal: {
      heading: string;
      body: string;
      different_needs_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryV2 {
      add_another_pet_modal {
        heading
        body
        different_needs_text
      }
    }
  }
`;

const AddAnotherPetModal: React.FC<AddAnotherPetModalProps> = ({
  onClose,
  onConfirm,
  pageTitle,
  ...props
}) => {
  const content = useStaticQuery<CsAddAnotherPetModal>(query).csPetQuoteSummaryV2
    .add_another_pet_modal;

  const quote = useCurrentQuote();
  const [addAnotherPet, setAddAnotherPet] = useState<boolean | undefined>();

  const body = quote
    ? replacePlaceholdersRichText(quotePlaceholders, quote)(content.body)
    : content.body;

  const modalHeadingId = 'add-another-pet-modal-heading';
  const radioInputId = 'add-another-pet-boolean-radio-input';

  return (
    <Modal
      disableBoxShadow
      onClose={(e) => {
        trackModalLinkClick('add another pet', 'close');
        onClose?.(e);
      }}
      aria-labelledby={modalHeadingId}
      gridItemProps={{
        desktop: fluidGridColumns.desktop,
        tabletLandscape: fluidGridColumns.tabletLandscape,
      }}
      {...componentProps(props)}>
      <Grid>
        <ModalGridItem desktop={10} tabletLandscape={10}>
          <ModalHeading id={modalHeadingId}>{content.heading}</ModalHeading>
          <BodyRichText
            id={`${radioInputId}-label`}
            html={body}
            onLinkClick={trackRichTextLinkClick(pageTitle, 'add another pet')}
          />
          <RadioInputGrid alignLeft>
            <GridItem desktop={6} tabletLandscape={6}>
              <BooleanRadioInput
                id={radioInputId}
                value={addAnotherPet}
                onChange={(newValue) => {
                  setAddAnotherPet(newValue);
                  trackModalLinkClick('add another pet', `radio - ${newValue}`);
                  if (newValue) {
                    onConfirm();
                  }
                }}
              />
            </GridItem>
          </RadioInputGrid>
          <div aria-live="polite">
            {addAnotherPet === false && (
              <DifferentNeedsRichText
                html={content.different_needs_text}
                onLinkClick={trackRichTextLinkClick(pageTitle, 'add another pet')}
              />
            )}
          </div>
        </ModalGridItem>
      </Grid>
    </Modal>
  );
};

export default AddAnotherPetModal;
